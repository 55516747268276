<template>
    <div id="app">
        <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" v-on:vdropzone-success="showSuccess"></vue-dropzone>
    </div>
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        icon: 'success',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true
    });
    export default {
        name: 'app',
        components: {
            vueDropzone: vue2Dropzone
        },
        data: function () {
            return {
                dropzoneOptions: {
                    url: "/uploadFile",
                    headers: {
                        "X-CSRF-TOKEN": document.head.querySelector("[name=csrf-token]").content
                    },
                    //Currently allowing images, docs and excel files, can be amended later - CB 19/03/2020
                    acceptedFiles: ".png,.jpg,.jpeg,.gif,.PNG,.JPG,.JPEG,.GIF,.pdf,.PDF,.doc,.DOC,.docx,.DOCX,.XLS,.xls,.XLSX,.xlsx",
                    thumbnailWidth: 150,
                    maxFilesize: 4
                }
            }
        },
        methods: {
            'showSuccess': function (file) {
                Toast.fire(
                    'File successfully uploaded.'
                );
                setTimeout(location.reload.bind(location), 2000);
            }
        }
    }
</script>
